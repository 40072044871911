<template>
	<div id="bg" @click="$emit('close')">
		<div id="content" class="row">
			<div class="col-xs-12">
				<button @click="$emit('close')">&times;</button>
			</div>
			<div class="col-xs-12">
				<img :src="src" :alt="alt">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Lightbox",
	props: ['src', 'alt']
}
</script>

<style scoped>
#bg {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	text-align: center;
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
}

#content {
	margin: 0 auto;
}

button {
	margin-bottom: 10px;
	background: none;
	font-size: 2rem;
	padding: 0;
	color: var(--light-grey);
	float: right;
}

button:hover, button:active {
	color: var(--text);
}

img {
	width: auto;
	max-height: 80vh;
	max-width: 80vw;
}
</style>
