<template>
	<div id="parent">
		<Lightbox v-if="showLightbox" :src="lightboxImg[0]" :alt="lightboxImg[1]" @close="showLightbox = false"/>

		<div id="top-bar">
			<button @click="goBack()">← Tillbaka</button>
		</div>
		<div id="body" class="row">
			<div class="col-sm-8 col-xs-12">
				<h1 v-if="selectedDatum.name" v-text="selectedDatum.name"></h1>
				<h2 v-if="selectedDatum.titles" v-text="selectedDatum.titles"></h2>
				<hr>
				<h3 v-if="selectedDatum.s_year" v-text="'+: ' + selectedDatum.s_year"></h3>
				<h3 v-if="selectedDatum.e_year" v-text="'✝: ' + selectedDatum.e_year"></h3>
				<h4 v-if="selectedDatum.cemetery" v-text="'Begravningsplats: ' + selectedDatum.cemetery"></h4>
				<p v-if="selectedDatum.text" v-text="selectedDatum.text"></p>
			</div>
			<div class="col-sm-4 col-xs-12" v-if="selectedDatum.name.length > 0 && imgExists(selectedDatum.id)">
				<div class="row">
					<div class="col-xs-12">
						<img @click="openLightbox(getImgUrl(selectedDatum.id), selectedDatum.name + ' - ' + selectedDatum.cemetery)" :src="getImgUrl(selectedDatum.id)" :alt="selectedDatum.name + ' - ' + selectedDatum.cemetery">
					</div>
					<div class="col-sm-4 col-xs-12" v-if="imgExists(`${selectedDatum.id}(2)`)">
						<img @click="openLightbox(getImgUrl(`${selectedDatum.id}(2)`), selectedDatum.name + ' - ' + selectedDatum.cemetery)" :src="getImgUrl(`${selectedDatum.id}(2)`)" :alt="selectedDatum.name + ' - ' + selectedDatum.cemetery">
					</div>
					<div class="col-sm-4 col-xs-12" v-if="imgExists(`${selectedDatum.id}(3)`)">
						<img @click="openLightbox(getImgUrl(`${selectedDatum.id}(3)`), selectedDatum.name + ' - ' + selectedDatum.cemetery)" :src="getImgUrl(`${selectedDatum.id}(3)`)" :alt="selectedDatum.name + ' - ' + selectedDatum.cemetery">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Lightbox from "@/components/Lightbox";
export default {
	name: "Datum",
	components: {Lightbox},
	props: ['id'],
	data() {
		return {
			data: {},
			selectedDatum: {
				first: '',
				last: '',
				name: '',
				year: '',
				text: '',
				titles: '',
				cemetery: '',
				s_year: '',
				e_year: '',
				id: ''
			},
			showLightbox: false,
			lightboxImg: ['', '']
		}
	},
	mounted() {
		this.getData()
		this.selectedDatum = this.data[Number(this.id)]
	},
	methods: {
		getData() {
			this.data = require('@/assets/data.json')
		},
		getImgUrl(pic) {
			try {
				return require('../assets/img/pantheon/' + pic + '.jpg')
			} catch {
				return require('../assets/img/pantheon/' + pic + '.jpeg')
			}
		},
		imgExists(pic) {
			try {
				require('../assets/img/pantheon/' + pic + '.jpg')
				return true
			} catch {
				try {
					require('../assets/img/pantheon/' + pic + '.jpeg')
					return true
				} catch {
					return false
				}
			}
		},
		openLightbox(img, alt) {
			this.lightboxImg = [img, alt]
			this.showLightbox = true
		},
		goBack() {
			this.$store.state.isBack = true
			this.$router.push('/')
		}
	}
}
</script>

<style scoped>
#parent {
	padding: 10px 20px;
}

#top-bar {
	background-color: var(--light-grey);
	padding: 10px;
}

#body {
	max-width: 80%;
	margin: auto;
	padding-top: 10px;
}

#body img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

#body img:hover {
	cursor: pointer;
}

button:hover {
	cursor: pointer;
}
</style>
